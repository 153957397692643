import * as THREE from "three";
import "./index.css";

import {GLTFLoader, OrbitControls} from "three-stdlib";

const scene = new THREE.Scene();

let mixer: THREE.AnimationMixer;
let modelReady = false;

const loader = new GLTFLoader();
loader.load("/andre-monster.glb", (gltf) => {
  mixer = new THREE.AnimationMixer(gltf.scene);

  gltf.scene.traverse((obj) => {
    if (obj instanceof THREE.Mesh) {
      obj.material.shininess = 0.1;
    }
  })
  const animationAction = mixer.clipAction(gltf.animations[0]);
  animationAction.play();
  // animationAction.repetitions = 0;

  scene.add(gltf.scene);
  modelReady = true;
});


scene.background = new THREE.Color(0x222222);
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

const renderer = new THREE.WebGLRenderer({
  //@ts-ignore
  canvas: document.getElementById("canvas"),
});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.outputEncoding = THREE.sRGBEncoding; // optional with post-processing

// new OrbitControls(camera, renderer.domElement);

scene.add(new THREE.AmbientLight(0xffffff, 0.5));
const dirLight = new THREE.DirectionalLight(0xffffff, 1);
dirLight.position.z = 10;
dirLight.position.y = 10;
scene.add(dirLight);

camera.position.y = 0.5;
camera.position.z = 2;

const clock = new THREE.Clock();
function animate() {
  // stats.begin();
  requestAnimationFrame(animate);

  if (modelReady) mixer.update(clock.getDelta())

  renderer.render(scene, camera);

  // stats.end();
}

animate();
